import { useState ,useContext} from "react";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { ThemeContext } from '../../../Contexts/Theme';
import {
  CommentContainer,
  Input,
  Label,
  FieldWrapper,
  FormWrapper,
  Header,
  TextArea,
  SubmitBtn,
} from "./CommentStyle";
const Comment = (props) => {
  const [DarkTheme] = useContext(ThemeContext);
  const [loading, setLoading] = useState(false);
  const [fieldsData, setFieldsData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    title: "",
    message: "",
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!fieldsData.title) {
      toast.remove();
      toast.error("عنوان نظر نمیتواند خالی باشد");
      return;
    }
    if (!fieldsData.message) {
      toast.remove();
      toast.error("متن نظر نمیتواند خالی باشد");
      return;
    }
    (async () => {
      try {
        setLoading(true);
        const params = new URLSearchParams();
        params.append("firstname", fieldsData.firstname.toString());
        params.append("lastname", fieldsData.lastname.toString());
        params.append("email", fieldsData.email.toString());
        params.append("title", fieldsData.title.toString());
        params.append("message", fieldsData.message.toString());
        const { data } = await axios.post(
          `https://api.karsooghmehregan.ir/api/contact/comment/`,
          params
        );
        setFieldsData((prev) => ({ ...prev, title: "", message: "" }));
        toast.success("نظر شما ثبت شد");
      } catch (error) {
        console.log(error);
        toast.error(`${error.response?.data?.error}`);
      } finally {
        setLoading(false);
      }
    })();
  };
  return (
    <>
      <Toaster position="bottom-center" />
      <CommentContainer id="Comment">
        <Header>ارسال نظر</Header>
        <form onSubmit={handleSubmit}>
          <FormWrapper>
            <FieldWrapper w={50}>
              <Label isDark={DarkTheme} >نام</Label>
              <Input isDark={DarkTheme} 
                id="firstname"
                name="firstname"
                placeholder="نام خود را وارد کنید"
                value={fieldsData.firstname}
                onChange={(e) => {
                  setFieldsData((prev) => ({
                    ...prev,
                    firstname: e.target.value,
                  }));
                }}
              />
            </FieldWrapper>
            <FieldWrapper w={50}>
              <Label isDark={DarkTheme} >نام خانوادگی</Label>
              <Input isDark={DarkTheme}   
              id="lastname"
                name="lastname"
                placeholder="نام خانوادگی  خود را وارد کنید"
                value={fieldsData.lastname}
                onChange={(e) => {
                  setFieldsData((prev) => ({
                    ...prev,
                    lastname: e.target.value,
                  }));
                }}
              />
            </FieldWrapper>
            <FieldWrapper w={50}>
              <Label isDark={DarkTheme} >ایمیل</Label>
              <Input isDark={DarkTheme} 
                id="email"
                name="email"
                placeholder="ایمیل خود را وارد کنید"
                value={fieldsData.email}
                onChange={(e) => {
                  setFieldsData((prev) => ({
                    ...prev,
                    email: e.target.value,
                  }));
                }}
              />
            </FieldWrapper>

            <FieldWrapper w={50}>
              <Label isDark={DarkTheme} >عنوان</Label>
              <Input isDark={DarkTheme} 
                id="title"
                name="title"
                placeholder="عنوان نظر خود را وارد کنید"
                value={fieldsData.title}
                onChange={(e) => {
                  setFieldsData((prev) => ({
                    ...prev,
                    title: e.target.value,
                  }));
                }}
              />
            </FieldWrapper>
            <FieldWrapper w={100}>
              <Label isDark={DarkTheme} >متن نظر</Label>
              <TextArea isDark={DarkTheme} 
                id="message"
                name="message"
                placeholder="نظر خودر را بنویسید ..."
                value={fieldsData.message}
                onChange={(e) => {
                  setFieldsData((prev) => ({
                    ...prev,
                    message: e.target.value,
                  }));
                }}
              ></TextArea>
            </FieldWrapper>
            <SubmitBtn isDark={DarkTheme} type="submit" disabled={loading}>
              {loading ? "در حال ارسال ..." : "ارسال نظر"}
            </SubmitBtn>
          </FormWrapper>
        </form>
      </CommentContainer>
    </>
  );
};
export default Comment;
