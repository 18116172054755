import styled from "styled-components";
import EstedadFont from "../../../assets/NavBar/Estedad-Regular.woff2";
import EstedadFontBold from "../../../assets/About/Estedad-Bold.woff2";
import user from "../../../assets/Team/User.svg";

export const TeamContainer = styled.div`
  margin: 0;
  object-fit: cover;
  min-height: 92vh;
  display: flex;
  flex-direction: column;
  align-items: start;
  direction: rtl;

  @font-face {
    font-display: swap;
    font-family: "Estedad";
    font-style: normal;
    font-weight: normal;
    src: url(${EstedadFont}) format("woff");
  }
  font-family: "Estedad";

  //can not select link
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none; /* Likely future */
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  margin: 2rem 15rem 2rem 0;

  @media screen and (max-width: 1900px) {
    margin: 2rem 8rem 0 0;
  }

  @media screen and (max-width: 1450px) {
    margin: 2rem 5rem 0 0;
  }

  @media screen and (max-width: 1280px) {
    margin: 2rem 2rem 0 0;
  }

  @media screen and (max-width: 990px) {
    flex-direction: column;
    width: 95%;
  }

  @media screen and (max-width: 650px) {
    width: 92%;
  }

  @media screen and (max-width: 585px) {
    margin: 0;
    width: 99%;
  }
`;

export const Title = styled.div`
  color: #85bc22;
  font-weight: bold;
  margin-left: 2rem;
  margin-top: 0.3rem;

  @font-face {
    font-display: swap;
    font-family: "EstedadBold";
    font-style: normal;
    font-weight: bold;
    src: url(${EstedadFontBold}) format("woff");
  }

  font-family: "EstedadBold";
  font-size: 2.5rem;

  @media screen and (max-width: 585px) {
    margin-right: 2rem;
  }
`;

export const More = styled.div`
  display: ${props => (props.show) ? "flex": "none"};
  flex-direction: row;
  align-items: center;
  gap: 40px;

  @media screen and (max-width: 750px) {
    margin: 0 auto;
    flex-direction: column;
    gap: 0;

  }

  @media screen and (max-width: 325px) {
    align-items: center;
  }
`;

export const Pages = styled.div`
  margin: 0 0 1rem;

  .pagination {
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
    list-style: none;
    direction: ltr;
    transition: all 0.5s ease;

    li:not(:first-child, :last-child) {
      :hover {
        color: ${props => (props.isDark) ? "#fff" : "#333"};
        border-color: ${props => (props.isDark) ? "#fff" : "#333"};
      }
    }

    li:first-child {
      border: 0px;
      margin: 0.5rem 0 0;

      a img{
        width: 1.2rem;
        transform: rotate(180deg);
      }
    }

    li:last-child {
      border: 0px;
      margin: 0.5rem 0 0;

      a img{
        width: 1.2rem;
      }
    }

    li{
      margin: 1rem 0.2rem 0;
      border: 1px solid #ccc;
      border-radius: 100%;
      color: #ccc;
      width: 30px;
      height: 30px;
      text-align: center;
      transition: all 0.5s ease;

      :hover {
        cursor: pointer;
      }
    }

    .break {
      border: 0;
      padding: 0;
      margin: 0.5rem 0 0;
      font-size: 1.3rem;
    }

    .disabled{
      :hover {
        cursor: unset; !important
      }
    }

    .active{
      background: #ccc;
      color: #333;
      font-weight: bold;
    }

    .active:hover {
      background: ${props => (props.isDark) ? "#292929" : "unset"};
    }

    li {
      display: none;
    }

    li:first-child,
    li:not(:nth-child(2) ~ li),
    li:nth-last-child(2),
    li:last-child,
    li.active,
    li.disabled,
    li.break {
      display: block;
    }

    @media screen and (max-width: 830px) {
      margin-right: 0;
    }

    @media screen and (max-width: 500px) {
      margin-right: 2rem;
    }
  }
`;

export const Filters = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: -1rem;

  .fill {
    background: #ccc;
    color: #333;
  }

  @media screen and (max-width: 830px) {
    margin-right: -2rem;
  }

  @media screen and (max-width: 505px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    text-align: center;
    margin: auto;
  }

  @media screen and (max-width: 435px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const Container = styled.div`
  color: ${props => (props.isDark) ? "#bdbdbd" : "#ccc"};
  border: 2px solid ${props => (props.isDark) ? "#bdbdbd" : "#ccc"};
  border-radius: 20px;
  padding: 2px 15px 3px;
  font-size: 1.2rem;
  font-weight: bold;
  margin: 0.5rem;
  transition: all 0.5s ease;

  :hover{
    color: ${props => (props.isDark) ? "#fff" : "#333"};
    border: 2px solid ${props => (props.isDark) ? "#fff" : "#333"};
    cursor: pointer;
  }

  @media screen and (max-width: 505px) {
    font-size: 1.1rem;
  }
`;

export const Description = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin: 2rem auto 1rem;
  row-gap: 2rem;
  column-gap: 5rem;
  transition: all 0.5s ease;

  @media screen and (max-width: 1135px) {
    column-gap: 2rem;
  }

  @media screen and (max-width: 990px) {
    column-gap: 1rem;
  }

  @media screen and (max-width: 940px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (max-width: 700px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 470px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const Part = styled.div`
  width: 100%;
  text-align: center;
`;

export const Image = styled.div`
  border: 1px solid #85bc22;
  border-radius: 60px;
  padding: 9px;
    &:hover img {
        transform: scale(1.2); 
    }
  // @media screen and (max-width: 680px) {
  //   border-radius: 50px;
  // }
`;

export const Pic = styled.img`
  border-radius: 50px;
  background: #999;
  display: block;
  margin: auto;
  width: 12rem;
  height: 12rem;
  position: relative;
  object-fit: ${props => (props.src === user) ? "none" : "cover"};
  object-position: center;

  // @media screen and (max-width: 680px) {
  //   width: 11rem;
  //   height: 11rem;
  //   border-radius: 40px;
  // }
`;

export const Text = styled.div`
`;

export const Name = styled.div`
  color: #85bc22;
  font-size: 1.3rem;
  font-weight: bold;
  margin-top: 12px;
`;

export const Objective = styled.div`
  color: ${props => (props.isDark) ? "#bdbdbd" : "#333"};
  font-weight: bold;
`;

export const MoreButton = styled.div`
  margin: 1rem auto;
  display: ${props => (props.show)? "unset" : "none"};
`;

export const Arrow = styled.img`
  display: block;
  width: 5rem;
  transition: all 0.4s ease;

  :hover{
    transform: scale(1.2);
    cursor: pointer;
  }
`;
