import { createContext } from "react";

export const ThemeContext = createContext(true);

export const ThemeProvider = ({ children }) => {
    return (
        <ThemeContext.Provider value={[true]}>
            {children}
        </ThemeContext.Provider>
    );
};
