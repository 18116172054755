import giphy from "../../../assets/Images/giphy.gif";
import styled from 'styled-components';
import EstedadFont from "../../../assets/NavBar/Estedad-Regular.woff2";
import RightArrow from "../../../assets/Heading/right-arrow.svg";

export const HeadingContainer = styled.div`
    margin: 0;
    object-fit: cover;
    min-height: 90vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    @font-face {
        font-display: swap;
        font-family: 'Estedad';
        font-style: normal;
        font-weight: normal;
        src: url(${EstedadFont}) format('woff');
    }
    font-family: "Estedad";
    direction: ltr;


    //can not select link
    -webkit-user-select: none;  /* Chrome all / Safari all */
    -moz-user-select: none;     /* Firefox all */
    -ms-user-select: none;      /* IE 10+ */
    user-select: none;          /* Likely future */
`


export const LeftContainer = styled.div`
    width: 50%;

    @media screen and (max-width: 1400px) {
        width: 60%;
    }

    @media screen and (max-width: 1050px) {
        width: 100%;
    }

    @media screen and (max-width: 700px) {
        display: none; /* مخفی کردن در گوشی‌ها */
    }
`;

export const RightContainer = styled.div`
    height: 100%;
    width: 45%;
    object-fit: cover;

    @media screen and (max-width: 1320px) {
        height: 110%;
        width: 50%;
    }

    @media screen and (max-width: 700px) {
        display: none; 
    }
`;

export const MobileGifWrapper = styled.div`
    display: none; 
    position: relative;
    width: 100vw;
    height: calc(100vh - 80px);
    overflow: hidden;

    @media screen and (max-width: 700px) {
        display: block;
    }
`;

export const MobileGif = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
`;

export const CenterText = styled.div`
    position: absolute;
    top: 50%;
    direction: rtl;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 7rem;
    color: #85BC22;
    font-weight: bold;
    z-index: 1;
    text-align: center;
    line-height: 1.4; 

text-shadow: 
    0 0 30px #000,
    0 0 35px #000;
    
    span {
        color: #A166FF; 
        font-size: 5rem; 
        font-weight: bold;
    }

    .smallText {
        color: #fff; 
        font-size: 1rem; 
        font-weight: normal;
        direction: rtl;
        text-align: right;
        padding: 2px 10px;
        background: rgba(0, 0, 0, 0.3);
        border-radius: 5px; 
        backdrop-filter: blur(5px); 
    }

    @media screen and (max-width: 700px) {
        font-size: 6rem;

        span {
            font-size: 3rem;
        }

        .smallText {
            font-size: 0.92rem;
            padding: 3px 15px;
        }
    }

    @media screen and (max-width: 400px) {
        font-size: 5rem;

        span {
            font-size: 2rem;
        }

        .smallText {
            font-size: 0.73rem;
            padding: 3px 10px;
        }
    }
`;




export const KarsoghImage = styled.img`
    height: 100%;
    width: 100%;
`

export const Images = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;

    @media screen and (max-width: 1400px) {
        justify-content: start;
    }

    @media screen and (max-width: 1050px) {
        justify-content: center;
        align-items: center;
        margin: auto;
        margin-left: 0;
        width: 100%;
    }

    @media screen and (max-width: 600px) {

        justify-content: center;
        margin-right: 0;
        flex-direction: column-reverse;
        width: 100%;
    }
`

export const SignUpButton = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 22%;
    transition: all 0.4s ease;

    :hover {
        transform: scale(1.1);
        cursor: pointer;
    }

    @media screen and (max-width: 790px) {
        width: 20%;
    }

    @media screen and (max-width: 700px) {
        width: 20%;
    }

    @media screen and (max-width: 600px) {
        margin-top: 1rem;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        width: 40%;
    }

    @media screen and (max-width: 480px) {
        width: 60%;
    }

    @media screen and (max-width: 405px) {
        width: 60%;
    }

    @media screen and (max-width: 355px) {
        width: 70%;
    }

    @media screen and (max-width: 320px) {
        width: 70%;
    }
`

export const Arrow = styled.div`
    background-image:url(${RightArrow});
    background-size: cover;
    background-repeat: no-repeat;
    display: inline-block;
    width: 8vw;
    height: 8vw;
    max-width: 1.5rem;
    max-height: 3.1rem;
    transition: all 0.3s ease;
    margin: auto;

    @media screen and (max-width: 600px) {
        width: 14vw;
        height: 14vw;
        transform: rotate(-90deg);
    }

    @media screen and (max-width: 340px) {
        width: 17vw;
        height: 17vw;
    }
`

export const SignUpText = styled.div`
    width: 70%;
    direction:rtl;
    user-select: none;
    color: #999;
    font-weight: bold;
    font-size: 1.2rem;
    text-align: center;

    @media screen and (max-width: 1680px) {
        width: 80%;
    }

    @media screen and (max-width: 1460px) {
        width: 90%;
    }

    @media screen and (max-width: 1300px) {
        width: 100%;
        font-size: 1.1rem;
        margin-bottom: 0.2rem;
    }

    @media screen and (max-width: 1050px) {
        width: 70%;
    }

    @media screen and (max-width: 940px) {
        width: 80%;
    }

    @media screen and (max-width: 850px) {
        width: 90%;
    }

    @media screen and (max-width: 790px) {
        width: 100%;
    }

    @media screen and (max-width: 600px) {
        margin: 0;
        margin-top: 0.5rem;
    }

    @media screen and (max-width: 530px) {
        margin: 0;
        margin-top: 0.5rem;
        margin-right: 1rem;
    }

    @media screen and (max-width: 480px) {
        margin-right: 1rem;
    }
`

export const TimerContainer = styled.div`
    width: 60%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;

    @media screen and (max-width: 600px) {
        flex-direction: column-reverse;
    }
`

export const Timer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #999;
    margin: 0 2rem 0 1rem;
    background: ${props => (props.isDark) ? "#bdbdbd" : "#f5f5f5"};
    padding: 0 20px;
    border-radius: 60px;
    height: 30rem;

    .timerLabel {
        color: ${props => (props.isDark) ? "#292929" : "unset"};
    }

    @media screen and (max-width: 1450px) {
        padding: 30px 20px;
    }

    @media screen and (max-width: 1320px) {
        font-size: 1rem;
    }

    @media screen and (max-width: 1050px) {
        font-size: 1.2rem;
    }

    @media screen and (max-width: 600px) {
        flex-direction: row;
        margin: 0;
        height: 10rem;

        .timerBox {
            display: flex;
            flex-direction: row;
        }

        .timerLabel {
            margin: 0 0.5rem;
        }
    }

    @media screen and (max-width: 545px) {
        padding-left: 2rem;
    }
`

export const HourGlass = styled.img`
    margin-bottom: 1rem;

    @media screen and (max-width: 1280px) {
        height: 6vw;
    }

    @media screen and (max-width: 1050px) {
        height: 9vw;
    }

    @media screen and (max-width: 850px) {
        height: 10vw;
    }

    @media screen and (max-width: 645px) {
        height: 12vw;
    }

    @media screen and (max-width: 520px) {
        height: 14vw;
    }

    @media screen and (max-width: 600px) {
        margin: 0 1rem 0 0;
    }

    @media screen and (max-width: 400px) {
        height: 16vw;
    }
`

export const KarsoghTitle = styled.img`
    height: 100%;
    width: 100%;

    @media screen and (max-width: 1180px) {
        width: 32rem;
        height: 32rem;
    }

    @media screen and (max-width: 1050px) {
        width: 30rem;
        height: 30rem;
    }

    @media screen and (max-width: 700px) {
        width: 23rem;
        height: 23rem;
    }

    @media screen and (max-width: 600px) {
        margin-bottom: 2rem;
        width: 30rem;
        height: 30rem;
    }

    @media screen and (max-width: 490px) {
        margin-bottom: 2rem;
        width: 28rem;
        height: 28rem;
    }

    @media screen and (max-width: 450px) {
        margin-bottom: 2rem;
        width: 25rem;
        height: 25rem;
    }

    @media screen and (max-width: 450px) {
        margin-bottom: 2rem;
        width: 22rem;
        height: 22rem;
    }

    @media screen and (max-width: 450px) {
        margin-bottom: 2rem;
        width: 150%;
    }
`
