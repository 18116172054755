import styles from './Safir.module.css';
import gil from '../../assets/Images/gil.png';
import logo from '../../assets/Images/logo.png';
import logoDark from '../../assets/Images/logoDark.png';
import resultFile from '../../assets/File/score.pdf';
import React, { useContext, useState, useEffect } from 'react';
import { ThemeContext } from '../../Contexts/Theme';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import {
  SafirContainer,
  Input,
  Label,
  FieldWrapper,
  FormWrapper,
  Header,
  TextArea,
  SubmitBtnPurple
} from '../../views/Landing/Comment/CommentStyle.js';


export default function Safir() {
  const [showModal, setShowModal] = useState(false);


    const handleTextClick = () => {
      setShowModal(true);
  };
  const [DarkTheme] = useContext(ThemeContext);
  const navigate = useNavigate();
  const [agreed, setAgreed] = useState(false);
  const [loading, setLoading] = useState(false);
const [fieldsData, setFieldsData] = useState({
  studentStatus: '1',
  fullName: '',
  nationalCode: '',
  birthDate: '',
  birthProvince: '',
  birthCity: '',
  mobile: '',
  telegramId: '',
  participatedInKarsough: '',
  school: '',
  highSchoolPrincipal: '',
  grade: '',
  promoProvince: '',
  promoCity: '',
  promoSchools: '',
  image: null,
  motivationLetter: ''
});
  const handleAgreementChange = (e) => {
    setAgreed(e.target.checked);
  };
    const handleCloseModal = () => {
      setShowModal(false);
  };

  const handleStudentStatusChange = (e) => {
    setFieldsData((prev) => ({
      ...prev,
      studentStatus: e.target.value,
    }));
  };

  const DarkThemeStyle = {
    backgroundColor: '#2E2E2E',
    color: 'white',
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

const formData = new FormData();
formData.append('name', fieldsData.fullName);
formData.append('national_code', fieldsData.nationalCode);
formData.append('birth_date', fieldsData.birthDate);
// birth_date = models.DateField(null=True, blank=True)
formData.append('birth_province', fieldsData.birthProvince);
formData.append('birth_city', fieldsData.birthCity);
formData.append('mobile', fieldsData.mobile);
formData.append('telegram_id', fieldsData.telegramId);
formData.append('participated_in_karsough', fieldsData.participatedInKarsough);
formData.append('school', fieldsData.school);
formData.append('high_school_principal', fieldsData.highSchoolPrincipal);
formData.append('grade', fieldsData.grade);
formData.append('promo_province', fieldsData.promoProvince);
formData.append('promo_city', fieldsData.promoCity);
formData.append('promo_schools', fieldsData.promoSchools);
formData.append('motivation_letter', fieldsData.motivationLetter);

    if (fieldsData.image) {
      formData.append('photo', fieldsData.image);
    } else {
      toast.error('لطفا یک تصویر انتخاب کنید');
      setLoading(false);
      return;
    }

    try {
      const response = await axios.post('https://api.karsooghmehregan.ir/api/u/add-user/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 201) {
        toast.success('اطلاعات با موفقیت ارسال شد');
      }
    } catch (error) {
      if (error.response) {
        console.error('Server Error:', error.response.data);
        toast.error(`خطا در ارسال اطلاعات: ${error.response.data.message || 'دیتا را به فرم درست و کامل وارد کنید'}`);
      } else if (error.request) {
        console.error('Request Error:', error.request);
        toast.error('مشکلی در ارسال درخواست به سرور پیش آمد');
      } else {
        console.error('General Error:', error.message);
        toast.error(`خطای عمومی: ${error.message}`);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleBackClick = () => {
    navigate('/');
  };

  return (
    <>
      <div className={styles['Container']} style={DarkTheme ? DarkThemeStyle : null}>
        <div className={styles['Whole-LeftSide']}>
            <img className={styles['GilPicture']} src={gil} alt="Gil" style={{ transform: 'scale(-0.85, 0.85)'}} />
        </div>

                <div className={styles['Whole-RightSide']}>
          <button 
            className={styles['BackButton']} 
            onClick={handleBackClick} 
            style={{ 
              position: 'absolute', 
              top: '20px', 
              left: '20px', 
              padding: '10px 20px', 
              backgroundColor: '#85bc22', 
              color: 'black', 
              border: 'none', 
              borderRadius: '5px', 
              fontSize: '18px',
              cursor: 'pointer',
              zIndex: '10',
              transition: 'background-color 0.3s'
            }}
            onMouseEnter={(e) => e.target.style.backgroundColor = '#7a27ff'}
            onMouseLeave={(e) => e.target.style.backgroundColor = '#85bc22'}
          >
            بازگشت
          </button>
          <div className={styles['CongratsText']} style={DarkTheme ? { color: 'white' } : null}>
            <div className={styles['CongratsTitle']} style={{ color: '#af7dff', textShadow: '2px 2px 4px black' }}>
            کارسوق به کمک شما نیاز داره!!!</div>
            <span>سلام بچه ها</span>
            <span>مثل دوره‌ی قبل این بار هم کارسوق به کمکتون نیاز داره</span>
            <span>برای کمک به کارسوق باید بچه‌های مدرسه‌تون رو با ما آشنا کنید...</span>
            <span>میدونیم که این کارو میکنید =))</span>
            <span>اما اگه رسمی‌تر و جدی‌تر کمکمون کنید اون وقت بهتون میگیم سفیر</span>
            <span >اگر از شرکت‌کنندگان دوره‌های قبلی هستید و دوست دارید سفیر شید اول تعهد نامه را تایید کنید بعد فرم پایین را پر کنید :)</span>
            
          <label style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
            <input
              type="checkbox"
              checked={agreed}
              onChange={handleAgreementChange}
              style={{ marginRight: '10px' }}
            />
            <h4
              onClick={handleTextClick} 
              style={{ color: '#85bc22' , fontSize : '1.3em', textDecoration: 'underline', cursor: 'pointer' }}
            >
متن تعهدنامه را خوانده ام و به آن متعهد میشوم. فرم ثبت نام سفیر را نمایش بده              </h4>
          </label>
          </div>
        </div>
      </div>
        {showModal && (
  <div
    className={styles['Modal']}
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'fixed', // Fixed positioning to ensure it's on top of other content
      top: '0',
      left: '0',
      right: '0',
      bottom: '0',
      backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
      zIndex: '10000',
    }}
  >
    <div
      className={styles['ModalContent']}
      style={{
        backgroundColor: '#cccccc',
        padding: '20px',
        borderRadius: '10px',
        maxWidth: '500px',
        width: '80%',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
        zIndex: '10001', // Ensure modal content is above the background
      }}
    >
      <h3>تعهد نامه سفیران کارسوق</h3>
      <p>به عنوان سفیر رسمی 25امین دورۀ کارسوق ریاضی مهرگان تعهد می‌دهم:</p>
            <p>- مانند همۀ دیگر کارسوقمندان، پرانرژی، شاد و متعهد حاضر شوم. 😊</p>
        <p>- ملزم به رعایت پوشش رسمی در مدارس باشم.</p>
<p>- با هماهنگی کامل با مدیران و مسئولین مرتبطِ سفارت، در مدارس حاضر شده و همچنین به ساعت و برنامۀ مقرر و هماهنگ شده نیز پایبند باشم.</p>
            <p>- سفارت کارسوقیه را در جریان مراحل طی شده و اقدامات صورت گرفته قرار می‌دهم.</p>
            <p>- به عنوان یک مهرگانی، رفتاری سنجیده و  مبادیِ آداب خواهم داشت.</p>
            <p>- و همچنین در نهایت از عنوان کردن هر گونه موضوع نامرتبط با مهرگان و محتواهای علمی در مدارس و کلاس‌ها نیز پرهیز کنم.</p>
            <button
        onClick={handleCloseModal}
        style={{
          padding: '10px 20px',
          backgroundColor: '#85bc22',
          color: 'white',
          border: 'none',
          borderRadius: '5px',
          cursor: 'pointer',
          marginTop: '20px',
        }}
      >
        بازگشت
      </button>
    </div>
  </div>
)}


      <div style={DarkTheme ? DarkThemeStyle : null}>

        <div >
           {agreed && (
          <SafirContainer id="Comment">
<Header style={{ color: '#af7dff', textShadow: '2px 2px 4px black' }}>
            فرم ثبت نام سفیر :
              </Header>
            <form onSubmit={handleSubmit}>
              <FormWrapper>
                <FieldWrapper w={33}>
                  <Label>نام کامل</Label>
                  <Input
                    id="fullName"
                    name="fullName"
                    placeholder="نام کامل تون"
                    value={fieldsData.fullName}
                    onChange={(e) =>
                      setFieldsData((prev) => ({ ...prev, fullName: e.target.value }))
                    }
                  />
                </FieldWrapper>
                  <FieldWrapper w={33}>
    <Label>کد ملی</Label>
    <Input
      id="nationalCode"
      name="nationalCode"
      placeholder="کد ملی"
      value={fieldsData.nationalCode}
      onChange={(e) => setFieldsData((prev) => ({ ...prev, nationalCode: e.target.value }))}
    />
  </FieldWrapper>
  <FieldWrapper w={33}>
    <Label>تاریخ تولد (شمسی)</Label>
    <Input
      id="birthDate"
      name="birthDate"
      placeholder="مثال: 1400/01/01"
      value={fieldsData.birthDate}
      onChange={(e) => setFieldsData((prev) => ({ ...prev, birthDate: e.target.value }))}
    />
  </FieldWrapper>


  <FieldWrapper w={33}>
    <Label>استان محل تولد</Label>
    <Input
      id="birthProvince"
      name="birthProvince"
      placeholder="استان محل تولد"
      value={fieldsData.birthProvince}
      onChange={(e) => setFieldsData((prev) => ({ ...prev, birthProvince: e.target.value }))}
    />
  </FieldWrapper>

  <FieldWrapper w={33}>
    <Label>شهر محل تولد</Label>
    <Input
      id="birthCity"
      name="birthCity"
      placeholder="شهر محل تولد"
      value={fieldsData.birthCity}
      onChange={(e) => setFieldsData((prev) => ({ ...prev, birthCity: e.target.value }))}
    />
  </FieldWrapper>

  <FieldWrapper w={33}>
    <Label>شماره موبایل</Label>
    <Input
      id="mobile"
      name="mobile"
      placeholder="شماره موبایل"
      value={fieldsData.mobile}
      onChange={(e) => setFieldsData((prev) => ({ ...prev, mobile: e.target.value }))}
    />
  </FieldWrapper>

  <FieldWrapper w={33}>
    <Label>آیدی تلگرام</Label>
    <Input
      id="telegramId"
      name="telegramId"
      placeholder="آیدی تلگرام"
      value={fieldsData.telegramId}
      onChange={(e) => setFieldsData((prev) => ({ ...prev, telegramId: e.target.value }))}
    />
  </FieldWrapper>

  <FieldWrapper w={33}>
    <Label>شرکت‌کننده در کارسوق در دوره؟</Label>
    <Input
      id="participatedInKarsough"
      name="participatedInKarsough"
      placeholder="دوره هایی که شرکت کردید"
      value={fieldsData.participatedInKarsough}
      onChange={(e) => setFieldsData((prev) => ({ ...prev, participatedInKarsough: e.target.value }))}
    />
  </FieldWrapper>
  
<FieldWrapper w={33}>
        <Label>دانشجویی یا دانش آموز؟</Label>
        <select
          id="studentStatus"
          name="studentStatus"
          value={fieldsData.studentStatus}
          onChange={handleStudentStatusChange}
          style={{ backgroundColor: '#141616', color: '#fff' }}
        >
          <option value="0">دانش آموز</option>
          <option value="1">دانشجو</option>
        </select>
      </FieldWrapper>

                <FieldWrapper w={33}>
                  <Label>مدرسه/دانشگاه</Label>
                  <Input
                    id="school"
                    name="school"
                    placeholder="نام مدرسه یا دانشگاهت"
                    value={fieldsData.school}
                    onChange={(e) =>
                      setFieldsData((prev) => ({ ...prev, school: e.target.value }))
                    }
                    />
                    </FieldWrapper>
                  
      {fieldsData.studentStatus === '0' && (
        <FieldWrapper w={33}>
          <Label>نام مدیر دبیرستان</Label>
          <Input
            id="highSchoolPrincipal"
            name="highSchoolPrincipal"
            placeholder="نام مدیر دبیرستان"
            value={fieldsData.highSchoolPrincipal}
            onChange={(e) =>
              setFieldsData((prev) => ({
                ...prev,
                highSchoolPrincipal: e.target.value,
              }))
            }
          />
        </FieldWrapper>
         )}      
                <FieldWrapper w={33}>
                  <Label>مقطع تحصیلی</Label>
                  <Input
                    id="grade"
                    name="grade"
                    placeholder="مقطع دانش آموز/ترم دانشگاه"
                    value={fieldsData.grade}
                    onChange={(e) =>
                      setFieldsData((prev) => ({ ...prev, grade: e.target.value }))
                    }
                  />
                </FieldWrapper>

  <FieldWrapper w={33}>
    <Label>استان سفارت</Label>
    <Input
      id="promoProvince"
      name="promoProvince"
      placeholder="استان مورد نظر برای سفارت"
      value={fieldsData.promoProvince}
      onChange={(e) => setFieldsData((prev) => ({ ...prev, promoProvince: e.target.value }))}
    />
  </FieldWrapper>
                
  <FieldWrapper w={33}>
    <Label>شهر سفارت</Label>
    <Input
      id="promoCity"
      name="promoCity"
      placeholder="شهر مورد نظر برای سفارت"
      value={fieldsData.promoCity}
      onChange={(e) => setFieldsData((prev) => ({ ...prev, promoCity: e.target.value }))}
    />
  </FieldWrapper>

  <FieldWrapper w={33}>
    <Label>همه مدارس سفارت</Label>
    <Input
      id="promoSchools"
      name="promoSchools"
      placeholder="نام مدارس مورد نظر برای سفارت"
      value={fieldsData.promoSchools}
      onChange={(e) => setFieldsData((prev) => ({ ...prev, promoSchools: e.target.value }))}
    />
  </FieldWrapper>

        <FieldWrapper w={33}>
          <Label> عکس رسمی از خودت با حجم کمتر از 8 مگ</Label>
          <Input
            type="file"
            id="image"
            name="image"
            accept="image/*"
            onChange={(e) => {
              const file = e.target.files[0];
              if (file) {
                e.target.setAttribute('data-has-file', 'true');
              } else {
                e.target.removeAttribute('data-has-file');
              }
              setFieldsData((prev) => ({ ...prev, image: file }));
            }}
          />
        </FieldWrapper>

  <FieldWrapper w={100}>
    <Label>انگیزه نامه</Label>
    <Input
      as="textarea"
      id="motivationLetter"
      name="motivationLetter"
      placeholder="چه انگیزه‌ای داشتید که سفیر شدید؟"
      value={fieldsData.motivationLetter}
      onChange={(e) => setFieldsData((prev) => ({ ...prev, motivationLetter: e.target.value }))}
    />
  </FieldWrapper>



                <SubmitBtnPurple type="submit" disabled={loading}>
                  {loading ? 'در حال ارسال ...' : 'ارسال اطلاعات'}
                </SubmitBtnPurple>
              </FormWrapper>
            </form>
            </SafirContainer>
                  )}

        </div>
      </div>
      <Toaster position="bottom-center" />
    </>
  );
}
