import { useEffect } from "react";
import {
  GalleryContainer,
  Header,
  Item,
  ItemContainer,
  SkeltonLoading,
} from "./GalleryStyle";
import axios from "axios";
import { useState } from "react";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import "yet-another-react-lightbox/plugins/captions.css";
import Captions from "yet-another-react-lightbox/plugins/captions";

function toFarsiNumber(n) {
  const farsiDigits = ["۰", "۱", "۲", "۳", "۴", "۵", "۶", "۷", "۸", "۹"];

  return n.toString().replace(/\d/g, (x) => farsiDigits[x]);
}

const Gallery = (props) => {
  const [galleryData, setGalleryData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [slides, setSlides] = useState(null);
  const [open, setOpen] = useState(false);
  useEffect(() => {
    (async function () {
      try {
        setLoading(true);
        const { data } = await axios.get(
          `https://api.karsooghmehregan.ir/api/gallery2`
        );
        setGalleryData(data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    })();
  }, []);
  return (
    <>
      <Lightbox
        open={open}
        close={() => setOpen(false)}
        slides={slides}
        plugins={[Thumbnails, Zoom, Fullscreen, Slideshow, Captions]}
        carousel={{
          imageFit: "contain",
          padding: "70px",
        }}
        thumbnails={{
          position: "bottom",
          width: 80,
          height: 80,
          border: 1,
          borderRadius: 10,
          padding: 8,
          gap: 10,
          showToggle: true,
        }}
        animation={{ zoom: 500 }}
        zoom={{
          maxZoomPixelRatio: 2,
          zoomInMultiplier: 2,
          doubleTapDelay: 300,
          doubleClickDelay: 300,
          doubleClickMaxStops: 2,
          keyboardMoveDistance: 50,
          wheelZoomDistanceFactor: 100,
          pinchZoomDistanceFactor: 100,
        }}
      />
      <GalleryContainer id="Gallery">
        <Header>گالری تصاویر</Header>
        <ItemContainer>
          {loading
            ? [...Array(3)].map((i) => <SkeltonLoading />)
            : galleryData
            ? galleryData.map((item) => {
                return (
                  <Item key={item.id}>
                    <div className="resume-flip">
                      <div className="resume-list">
                        <div
                          className="resume-list-front resume-list-1"
                          style={{
                            backgroundImage: `url(https://api.karsooghmehregan.ir${item.images[0].src})`,
                          }}
                        >
                          <div className="overlay"></div>
                          <div className="flip-content">
                            <h5>دوره ی {toFarsiNumber(item.periodNum)}</h5>
                          </div>
                        </div>
                        <div className="resume-list-back">
                          <div className="flip-content">
                            <div className="icon">
                              <svg
                                width="55px"
                                height="55px"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M13.6471 16.375L12.0958 14.9623C11.3351 14.2694 10.9547 13.923 10.5236 13.7918C10.1439 13.6762 9.73844 13.6762 9.35878 13.7918C8.92768 13.923 8.5473 14.2694 7.78652 14.9623L4.92039 17.5575M13.6471 16.375L13.963 16.0873C14.7238 15.3944 15.1042 15.048 15.5352 14.9168C15.9149 14.8012 16.3204 14.8012 16.7 14.9168C17.1311 15.048 17.5115 15.3944 18.2723 16.0873L19.4237 17.0896M13.6471 16.375L17.0469 19.4528M17 9C17 10.1046 16.1046 11 15 11C13.8954 11 13 10.1046 13 9C13 7.89543 13.8954 7 15 7C16.1046 7 17 7.89543 17 9ZM21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
                                  stroke="#ffffff"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </div>
                            <div>
                              <h5>{item.description}</h5>
                            </div>
                            <div>
                              <button
                                onClick={(e) => {
                                  e.preventDefault();
                                  setSlides(
                                    item.images.map((img) => ({
                                      src: `https://api.karsooghmehregan.ir${img.src}`,
                                      description: img.caption,
                                    }))
                                  );
                                  setOpen(true);
                                }}
                              >
                                مشاهده تصاویر
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Item>
                );
              })
            : null}
        </ItemContainer>
      </GalleryContainer>
    </>
  );
};

export default Gallery;
