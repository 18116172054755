import React, { useState, useContext } from 'react';
import Logo from "../../../assets/Heading/Big_Logo.svg";
import Logo_Dark from "../../../assets/Heading/Big_Logo_Dark.svg";
import Title from "../../../assets/Heading/Main_title2.png";
import Title_Dark from "../../../assets/Heading/main_25.jpg";
import MyTimer from '../../../Components/Timer';
import Hour from "../../../assets/Heading/Hourglass.svg";
import Hour_Dark from "../../../assets/Heading/Hourglass_Dark.svg";
import giphy from "../../../assets/Images/bg2.gif";

import { ThemeContext } from '../../../Contexts/Theme';
import {
    HeadingContainer,
    LeftContainer,
    RightContainer,
    KarsoghImage,
    Images,
    SignUpButton,
    TimerContainer,
    Timer,
    KarsoghTitle,
    Arrow,
    SignUpText,
    HourGlass,
    MobileGifWrapper,
    MobileGif,
    CenterText
} from "./HeadingStyle.js";

const Heading = () => {
    const time = new Date();
    const start_time = new Date("2023-8-16");
    time.setSeconds(Math.abs(start_time - time) * 0.001);

    const [DarkTheme] = useContext(ThemeContext);

    const [isGifLoaded, setIsGifLoaded] = useState(false);

    const handleGifLoad = () => {
        setIsGifLoaded(true);
    };

    return (
        <HeadingContainer id="Home">
            <LeftContainer>
                <Images>
                    {/* <SignUpButton>
                        <SignUpText>مرحله حضوری</SignUpText>
                        <Arrow />
                    </SignUpButton> */}

                    <TimerContainer>
                        <KarsoghTitle src={DarkTheme ? Title_Dark : Title} />
                    </TimerContainer>
                </Images>
            </LeftContainer>

            <RightContainer>
                <KarsoghImage src={DarkTheme ? Logo_Dark : Logo} />
            </RightContainer>

            <MobileGifWrapper>
                <MobileGif 
                    src={giphy} 
                    alt="Mobile GIF" 
                    onLoad={handleGifLoad} 
                    style={{ opacity: isGifLoaded ? 1 : 0 }} 
                />
                
                {isGifLoaded && (
                    <CenterText>
                        <div className="smallText">دبیرخانه امور پژوهشی سازمان استعدادهای</div>
                        <div className="smallText">درخشان استان اصفهان با همکاری دانش</div>
                        <div className="smallText">آموختگان سمپاد استان  برگزار میکند:</div>

                        کارسوق ریاضی مهرگان
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <span>دوره 25</span>
                    </CenterText>
                )}
            </MobileGifWrapper>
        </HeadingContainer>
    );
};

export default Heading;
