import styled from "styled-components";
import EstedadFont from "../../../assets/NavBar/Estedad-Regular.woff2";
import EstedadFontBold from "../../../assets/About/Estedad-Bold.woff2";

export const FAQContainer = styled.div`
  margin: 0;
  object-fit: cover;
  min-height: 92vh;
  display: flex;
  flex-direction: column;
  align-items: start;
  direction: rtl;

  @font-face {
    font-display: swap;
    font-family: "Estedad";
    font-style: normal;
    font-weight: normal;
    src: url(${EstedadFont}) format("woff");
  }
  font-family: "Estedad";

  //can not select link
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none; /* Likely future */
`;

export const Header = styled.div`
  color: ${props => (props.isDark) ? "#a166ff": "#7b27ff"};
  font-weight: bold;
  margin: 2rem 15rem 2rem 0;

  @font-face {
    font-display: swap;
    font-family: "EstedadBold";
    font-style: normal;
    font-weight: bold;
    src: url(${EstedadFontBold}) format("woff");
  }

  font-family: "EstedadBold";
  font-size: 3rem;

  @media screen and (max-width: 1720px) {
    margin: 3rem 12rem 0 0;
  }

  @media screen and (max-width: 1600px) {
    margin: 2rem 10rem 0 0;
  }

  @media screen and (max-width: 1300px) {
    margin: 2rem 4rem 0 0;
  }

  @media screen and (max-width: 800px) {
    margin: 2rem 3rem 0 0;
  }

  @media screen and (max-width: 660px) {
    margin: 2rem 2rem 0 0;
  }

  @media screen and (max-width: 575px) {
    font-size: 2.5rem;
  }

  @media screen and (max-width: 400px) {
    margin-right: 1.5rem;
  }

  @media screen and (max-width: 350px) {
    margin-right: 1rem;
  }
`;

export const Description = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-evenly;
  transition: all 0.3s ease;
`;

export const RightContainer = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  margin-right: 12rem;

  @media screen and (max-width: 1680px) {
    margin-right: 10rem;
  }

  @media screen and (max-width: 1510px) {
    margin-right: 8rem;
  }

  @media screen and (max-width: 1470px) {
    width: 45%;
    margin-top: 2rem;
  }

  @media screen and (max-width: 1315px) {
    width: 50%;
    margin-top: 2rem;
  }

  @media screen and (max-width: 1250px) {
    margin-right: 4rem;
  }

    @media screen and (max-width: 880px) {
        margin-right: 0;
        width: 70%;
    }

  @media screen and (max-width: 800px) {
    margin-bottom: 2rem;
  }

    @media screen and (max-width: 500px) {
        width: 80%;
    }

    //////////////////////////////////////////
    // fixing FAQ parts:

    .question:hover {
      cursor: pointer;
    }
    
    .question {
      font-size: 1.3rem;
      color: ${props => (props.isDark) ? "#fff" : "#333"};
      user-select: none;
      transition: color 0.3s;
      width: 100%;

      @media screen and (max-width: 1470px) {
          font-size: 1.2rem;
      }
    }
    
    .qna .answer {
      height: 0px;
      transition: 0.3s linear;
      overflow: hidden;
      font-size: 1.1rem;
      font-weight: light;
      color: ${props => (props.isDark ? "#fff" : "#333")};
      margin-top: 1rem;
      cursor: normal;
    }

    .qna .answer p {
      margin-right: 10px;
    }

    .qna.active .answer {
      border-top: 1px solid ${props => (props.isDark) ? "#000" : "#999"};
    }
`

export const Part = styled.div`
  display: flex;
  flex-direction: column;
  background: ${props => (props.isDark) ? "#4c5052" : "#f5f5f5"};
  border-radius: 20px;
  margin: 1rem 0 0 0;
  padding: 5px 30px;
  width: 90%;
 
  :hover {
    cursor: pointer;
  }

  @media screen and (max-width: 1680px) {
    width: 100%;
  }
`;

export const LeftContainer = styled.div`
  width: 45%;
  object-fit: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;

    @media screen and (max-width: 880px) {
        display: none;
    }
`

export const KarsoghImage = styled.img`
  width: 70%;
  margin-right: 5rem;

  @media screen and (max-width: 1315px) {
    width: 80%;
    margin-right: 0;
  }
`;
