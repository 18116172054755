import React, { useContext } from "react";
import { FaBars } from "react-icons/fa";
import Sidebar from "./Sidebar/Sidebar";
import { ThemeContext } from "../../../Contexts/Theme";
import AuthContext from "../../../Contexts/AuthContext";
import { useNavigate, useLocation } from "react-router-dom";
import { scroller } from "react-scroll";

import {
  MobileIcon,
  Nav,
  NavbarContainer,
  NavBtn,
  NavBtnLink,
  NavItem,
  NavLinks,
  NavMenu,
  KarsoghLogo,
} from "./NavBarStyle.js";

const NavBar = (props) => {
  const AuthCtx = useContext(AuthContext);
  const [DarkTheme] = useContext(ThemeContext);
  const navigate = useNavigate();
  const location = useLocation();

  const shit = (document.body.style.background = DarkTheme ? "#292929" : "#fff");

  const handleLinkClick = (sectionId) => {
    navigate("/");

    setTimeout(() => {
      scroller.scrollTo(sectionId, {
        duration: 500,
        smooth: "easeInOutQuart",
        offset: -80,
      });
    }, 100);
  };

  return (
    <>
      <Sidebar isOpen={props.isOpen} toggle={props.toggle} />
      <Nav isDark={DarkTheme}>
        <NavbarContainer>
          <MobileIcon onClick={props.toggle} isDark={DarkTheme}>
            <FaBars />
          </MobileIcon>
          {/* <NavBtn>
            {location.pathname !== "/safir" && (
              <NavBtnLink to="/safir" bg={"#7a27ff"} filled={"true"}>
                ثبت نام سفیر ها
              </NavBtnLink>
            )}
          </NavBtn> */}
          <NavMenu>
            <NavItem>
              <NavLinks
                onClick={() => handleLinkClick("Team")}
                isDark={DarkTheme}
              >
                کادر برگزاری
              </NavLinks>
            </NavItem>

            <NavItem>
              <NavLinks
                onClick={() => handleLinkClick("Comment")}
                isDark={DarkTheme}
              >
                انتقاد و پیشنهاد
              </NavLinks>
            </NavItem>

            <NavItem>
              <NavLinks
                onClick={() => handleLinkClick("Gallery")}
                isDark={DarkTheme}
              >
                گالری تصاویر
              </NavLinks>
            </NavItem>

            <NavItem>
              <NavLinks
                onClick={() => handleLinkClick("News")}
                isDark={DarkTheme}
              >
                اخبار
              </NavLinks>
            </NavItem>

            <NavItem>
              <NavLinks
                onClick={() => handleLinkClick("FAQ")}
                isDark={DarkTheme}
              >
                پرسش و پاسخ
              </NavLinks>
            </NavItem>

            <NavItem>
              <NavLinks
                onClick={() => handleLinkClick("About")}
                isDark={DarkTheme}
              >
                درباره
              </NavLinks>
            </NavItem>

            <NavItem>
              <NavLinks
                onClick={() => handleLinkClick("Home")}
                isDark={DarkTheme}
              >
                خانه
              </NavLinks>
            </NavItem>

            <NavItem hover={"none"}>
              <NavLinks
                onClick={() => handleLinkClick("Home")}
                Logo={true}
              >
                <KarsoghLogo isDark={DarkTheme} />
              </NavLinks>
            </NavItem>
          </NavMenu>
        </NavbarContainer>
      </Nav>
    </>
  );
};

export default NavBar;
