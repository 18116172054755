import React, { useContext, useEffect, useState, useRef } from 'react';
import ReactPaginate from 'react-paginate';
import Arrow_Icon from "../../../assets/Team/Arrow_Down.svg";
import RightArrow from "../../../assets/Team/right-arrow.svg";
import user from "../../../assets/Team/User.svg";
import { ThemeContext } from '../../../Contexts/Theme';
import {
    TeamContainer,
    Header,
    Pages,
    Description,
    Title,
    Part,
    Image,
    Pic,
    Text,
    Name,
    Objective
} from './TeamStyle.js';

const data = [
    {
        id: 1,
        title: '',
        items: [
            { id: 100, fullName: 'کامیار دلوی', image: require('../../../assets/Images/کامیار_دلوی.jpg'), specialTask: 'بیگ باس (مسئول کل رویداد)' },
            { id: 101, fullName: 'علی نقشینه ارجمند', image: require('../../../assets/Images/علی_نقشینه_ارجمند.jpg'), specialTask: 'دبیر' },
            { id: 102, fullName: 'عمید خدایی', image: require('../../../assets/Images/عمید_خدایی.jpg'), specialTask: 'نایب دبیر' },
            { id: 103, fullName: 'ملیکا مشرفی', image: require('../../../assets/Images/ملیکا_مشرفی.jpg'), specialTask: 'نایب دبیر' },
            { id: 104, fullName: 'خورشید باهوش', image: require('../../../assets/Images/خورشید_باهوش.jpg'), specialTask: 'مسئول علمی' },
            { id: 109, fullName: 'محمدمهدی مصطفوی', image: require('../../../assets/Images/سید_محمد_مهدی_مصظفوی.jpg'), specialTask: 'مسئول علمی' },
            { id: 105, fullName: 'بابک نظام زاده', image: require('../../../assets/Images/بابک_نظام_زاده.jpg'), specialTask: 'مسئول رسانه' },
            { id: 108, fullName: 'ترانه عبدالهی', image: require('../../../assets/Images/ترانه_عبداللهی.jpg'), specialTask: 'مسئول فنی' },
            { id: 107, fullName: 'حسین پارساپور', image: require('../../../assets/Images/حسین_پارساپور.jpg'), specialTask: 'مسئول مستندسازی' },
            { id: 108, fullName: 'سوگل زمانیان', image: require('../../../assets/Images/سوگل_زمانیان.jpg'), specialTask: 'مسئول سوم علمی' },
            { id: 106, fullName: 'آرتمیس رفیعی', image: require('../../../assets/Images/آرتمیس_رفیعی.jpg'), specialTask: 'مسئول روابط عمومی' }
        ]
    }
];

const MemberPerPage = (window.innerWidth > 940) ? 8 : ((window.innerWidth > 700) ? 9 : 8);
const toPersianNumber = (number) => {
    const persianDigits = ['۰', '۱', '۲', '۳', '۴', '۵', '۶', '۷', '۸', '۹'];
    return number.toString().replace(/\d/g, (digit) => persianDigits[digit]);
};

const Team = () => {
    const [DarkTheme] = useContext(ThemeContext);
    const [currentPage, setCurrentPage] = useState(0);
    const pageData = useRef([]);
    const [pageCount, setPageCount] = useState(1);

    useEffect(() => {
        const temp = data[0]['items'];
        pageData.current = temp;
        setPageCount(Math.ceil(temp.length / MemberPerPage));
    }, []);

    const handlePageClick = ({ selected: selectedPage }) => {
        setCurrentPage(selectedPage);
    };

    const offset = currentPage * MemberPerPage;

    return (
        <TeamContainer id="Team">
            <Header>
                <Title>کادر برگزاری</Title>
                <Pages isDark={DarkTheme}>
                    <ReactPaginate
                        previousLabel={<img src={RightArrow} alt="<-" />}
                        nextLabel={<img src={RightArrow} alt="->" />}
                        pageCount={pageCount}
                        onPageChange={handlePageClick}
                        containerClassName={'pagination'}
                        previousLinkClassName={"previous"}
                        nextLinkClassName={"next"}
                        disabledClassName={'disabled'}
                        activeClassName={'active'}
                        forcePage={currentPage}
                        pageLabelBuilder={(page) => toPersianNumber(page)}
                    />
                </Pages>
            </Header>

            <Description>
                {pageData.current.slice(offset, offset + MemberPerPage).map((element) => (
                    <Part key={element.id}>
                        <Image>
                            <Pic src={element.image || user} alt="Image" />
                        </Image>
                        <Text>
                            <Name>{element.fullName}</Name>
                            <Objective isDark={DarkTheme}>
                                {/* {data[0].title}
                                ، */}
                                {element.specialTask}
                            </Objective>
                        </Text>
                    </Part>
                ))}
            </Description>
        </TeamContainer>
    );
};

export default Team;
