import React, { useContext } from "react";
import { ThemeContext } from "../../../Contexts/Theme.js";
import {
  AboutContainer,
  Description,
  TimeLine,
  Header,
  Content,
  Text,
  Video,
  Part,
  Dots,
  Title,
  Diamond,
  Hexagon,
  Circle,
  RectangleRounded,
} from "./AboutStyle.js";

const About = (props) => {
  const src =
    "https://www.aparat.com/video/video/embed/videohash/4twoM/vt/frame";

  const [DarkTheme] = useContext(ThemeContext);

  return (
    <AboutContainer id="About">
      <Description>
        <Header isDark={DarkTheme}>کارسوق؟ کارسوق کیه؟</Header>
        <Content>
          <Video width="100%" src={src} allowFullScreen={true} />
          <Text isDark={DarkTheme}>
            «کارسوق ریاضی» یه رویداد علمی و تعاملی که توسط دانش‌آموزان و
            دانشجویان نه چندان بزرگ‌تر از شما در سراسر کشور برگزار می‌شه! هدف
            کارسوق اینه که مباحث زیبا و جذاب ریاضی رو به شکلی متفاوت و به دور از
            روش‌های معمول و استرس امتحان و نمره‌دهی به بچه‌ها ارائه کنه؛ به
            عبارت دیگه، کارسوق ریاضی مهرگان در تلاشه تا چهره‌ی ریاضی رو خارج از
            غالب‌های خشک رایج به نمایش بگذاره و به دانش‌آموزان نشون بده که ریاضی
            واقعی چیه.
          </Text>
        </Content>
      </Description>
      <TimeLine>
    <Part id="circle">
        <Title>۲۰ تا ۳۰ بهمن</Title>
        <Circle isDark={DarkTheme}>
            <span>ثبت نام</span>
        </Circle>
    </Part>
    <Dots id="1">
        <span className="dot"></span>
        <span className="dot"></span>
        <span className="dot"></span>
        <span className="dot"></span>
        <span className="dot"></span>
        <span className="dot"></span>
        <span className="dot"></span>
    </Dots>

    <Part id="diamond">
        <Diamond isDark={DarkTheme}>
            <span>مرحله اول</span>
        </Diamond>
        <Title>۱۰ اسفند</Title>
    </Part>
    <Dots id="3">
        <span className="dot"></span>
        <span className="dot"></span>
        <span className="dot"></span>
        <span className="dot"></span>
        <span className="dot"></span>
        <span className="dot"></span>
        <span className="dot"></span>
    </Dots>

    <Part id="hexagon">
        <Title>۲۹ فروردین</Title>
        <Hexagon isDark={DarkTheme}>
            <span>مرحله دوم</span>
        </Hexagon>
    </Part>
    <Dots id="2">
        <span className="dot"></span>
        <span className="dot"></span>
        <span className="dot"></span>
        <span className="dot"></span>
        <span className="dot"></span>
        <span className="dot"></span>
        <span className="dot"></span>
    </Dots>

    <Part id="rectangle">
        <RectangleRounded isDark={DarkTheme}>
            <span>مرحله نهایی حضوری اصفهان</span>
        </RectangleRounded>
        <Title>۸ تا ۱۰ مرداد</Title>
    </Part>
</TimeLine>

    </AboutContainer>
  );
};

export default About;
