import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";
import { Link as LinkS } from "react-scroll";
import EstedadFont from "../../../assets/NavBar/Estedad-Regular.woff2";
import Logo from "../../../assets/NavBar/Small_Logo.svg";
import Logo_Dark from "../../../assets/NavBar/Small_Logo_Dark.svg";

export const Nav = styled.nav`
  align-items: center;
  background: ${(props) => (props.isDark ? "#292929" : "#fff")};
  border-bottom: 3px solid ${(props) => (props.isDark ? "#595959" : "#f5f5f5")};
  display: flex;
  justify-content: center;
  height: 80px;
  position: sticky;
  top: 0;
  transition: background-color 1s ease-out, color 250ms ease-in-out;
  z-index: 10;

  @font-face {
    font-display: swap;
    font-family: "Estedad";
    font-style: normal;
    font-weight: normal;
    src: url(${EstedadFont}) format("woff");
  }
  font-size: 1.1rem;
  font-family: "Estedad";
  direction: ltr;

  //can not select link
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none; /* Likely future */

  @media screen and (max-width: 960px) {
    transition: 0.8s all ease;
  }
`;

export const NavbarContainer = styled.div`
  display: flex;
  height: 80px;
  justify-content: space-between;
  max-width: 1370px;
  padding: 0 24px;
  width: 100%;
  z-index: 1;

  @media screen and (max-width: 1475px) {
    max-width: 1200px;
  }
`;

export const MobileIcon = styled.div`
  display: none;

  @media screen and (max-width: 830px) {
    color: ${(props) => (props.isDark ? "#bdbdbd" : "#000")};
    cursor: pointer;
    display: block;
    font-size: 1.8rem;
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(-100%, 40%);
  }
`;

export const NavMenu = styled.ul`
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style: none;
  margin-right: -22px;
  text-align: center;
  width: 65vw;

  @media screen and (max-width: 1475px) {
    width: 70vw;
  }

  @media screen and (max-width: 1190px) {
    width: 75vw;
  }

  @media screen and (max-width: 1070px) {
    width: 80vw;
  }

  @media screen and (max-width: 985px) {
    width: 85vw;
  }

  @media screen and (max-width: 910px) {
    width: 90vw;
  }

  @media screen and (max-width: 850px) {
    width: 95vw;
  }

  @media screen and (max-width: 830px) {
    display: none;
  }
`;

export const NavItem = styled.li`
  height: 80px;
  position: relative;

  &:after {
    display: block;
    position: absolute;
    content: "";
    border-bottom: solid 3px #85bc22;
    transform: scaleX(0);
    bottom: 0;
    transition: transform 250ms ease-in-out;
  }
  &:hover:after {
    width: ${(props) => (props.hover === "none" ? "" : "100%")};
    transform: ${(props) => (props.hover === "none" ? "" : "scaleX(1)")};
  }
`;

export const NavLinks = styled(LinkS)`
  align-items: center;
  color: ${(props) => (props.isDark ? "#b2b2b2" : "#838485")};
  display: flex;
  height: 100%;
  padding: 0 1rem;
  text-decoration: none;
  transition: all 0.3s ease;
  font-size: 1.2rem;
  font-weight: bold;

  &.active,
  &:hover {
    color: ${(props) => (props.isDark ? "#fff" : "#383838")};
  }
`;

export const NavBtn = styled.nav`
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 830px) {
    position: absolute;
    right: 60px; 
    top: 50%;
    transform: translateY(-50%);
    display: flex;
  }
`;

export const NavBtnLink = styled(LinkR)`
    background-color: ${(props) => (props.filled ? props.bg : "transparent")};
    border: 3px solid ${(props) => props.bg};
    border-radius: 30px;
    color: ${(props) => (props.bg === "#333333" ? "#333" : "#fff")};
    cursor: pointer;
    font-size: 1.2rem;
    font-weight: bold;
    margin: 3px !important;
    outline: none;
    padding: 5px 22px;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
    white-space: nowrap;

    animation: vibrate 2.5s infinite;
    animation-delay: 0s;
    animation-timing-function: linear;

    @keyframes vibrate {
      5%, 11%, 17% { transform: rotate(-2deg); }
      8%, 14%, 20% { transform: rotate(2deg); }
      21% { transform: none; }
    }

    &:hover {
        transform: scale(1);
        opacity: 0.8;
        transition: all 0.2s ease-in-out;
    }

    @media screen and (max-width: 830px) {
        font-size: 1rem;
        padding: 6px 14px;
        border-width: 2px;
    }
`;


export const KarsoghLogo = styled.div`
  background-image: ${(props) =>
    props.isDark ? `url(${Logo_Dark})` : `url(${Logo})`};
  background-size: cover;
  background-repeat: no-repeat;
  display: block;
  max-width: 50px;
  max-height: 50px;
  width: 48px;
  height: 48px;
  transition: all 0.35s ease;

  &:hover {
    cursor: pointer;
    transform: scale(1.15);
  }
`;
