import React, { useCallback, useEffect, useState } from "react";
import classes from "./Profile.module.css";
import axios, * as others from "axios";

const Information = () => {
  const [isLoading, setIsLoading] = useState(0);
  const [userData, setUserData] = useState(null);
  const getUserInformation = useCallback(async () => {
    const response = await axios.get(
      `https://api.karsooghmehregan.ir/api/u/auth/users/me/`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("token")}`,
        },
      }
    );
    setUserData(response);
  }, []);
  useEffect(async () => {
    getUserInformation();
  }, [getUserInformation]);
  console.log(userData);
  return (
    <React.Fragment>
      <form className={classes.innerForm}>
        <div className={classes.inputGroup}>
          <div className={classes.inputWrapper}>
            <span>نام:</span>
            <input
              disabled={true}
              value={
                userData != null ? userData.data.firstname : "درحال پردازش"
              }
              name="firstname"
              type={"text"}
              placeholder="نام"
            ></input>
          </div>
          <div className={classes.inputWrapper}>
            <span>نام خانوادگی:</span>
            <input
              disabled={true}
              value={userData != null ? userData.data.lastname : "درحال پردازش"}
              name="lastname"
              type={"text"}
              placeholder="نام خانوادگی"
            ></input>
          </div>
        </div>
        <div className={classes.inputGroup}>
          <div className={classes.inputWrapper}>
            <span>شماره همراه:</span>
            <input
              disabled={true}
              value={userData != null ? userData.data.phone : "درحال پردازش"}
              name="phone"
              type={"text"}
              placeholder="تلفن همراه"
            ></input>
          </div>
          <div className={classes.inputWrapper}>
            <span>ایمیل:</span>
            <input
              disabled={true}
              value={userData != null ? userData.data.email : "درحال پردازش"}
              name="email"
              type={"text"}
              placeholder="ایمیل"
            ></input>
          </div>
        </div>
        <div className={classes.inputGroup}>
          <div className={classes.inputWrapper}>
            <span>مدرسه:</span>
            <input
              disabled={true}
              value={userData != null ? userData.data.school : "درحال پردازش"}
              name="school"
              type={"text"}
              placeholder="مدرسه"
            ></input>
          </div>
          <div className={classes.inputWrapper}>
            <span>شهر:</span>
            <input
              disabled={true}
              value={userData != null ? userData.data.city : "درحال پردازش"}
              name="city"
              type={"text"}
              placeholder="شهر"
            ></input>
          </div>
        </div>
        <div className={classes.inputGroup}></div>
        <button
          className={`${classes.paymentButton} ${
            userData != null
              ? userData.data.is_paid != true
                ? classes.isNotPaid
                : ""
              : ""
          }`}
        >
          {userData != null
            ? userData.data.is_paid == true
              ? "پرداخت شده"
              : "پرداخت کنید"
            : "درحال پردازش"}
        </button>
      </form>
    </React.Fragment>
  );
};

export default Information;
